import Breadcrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import React, { useEffect, useRef } from "react";
import { useAppSelector, AppDispatch } from "src/store/store";
import { useNavigate, useParams } from "react-router-dom";
import TopOptions from "./topOptions/topOptions";
import TopDetails from "./topDetails/topDetails";
import TopTabs from "./topTabs/topTabs";
import "./placementDetails.scss";
import {
  deletePlacement,
  getAccommodationStatus,
  getEmailSettingsForSite,
  setIsConfirmDeleteModalOpen,
  getPlacementDetails,
  setPlacementDetails,
  setPlacementId,
  setIsConfirmUpdateRestrictionsModalOpen,
  setIsAlternativePlacementModalOpen,
  setIsRestrictionsModalOpen,
  setIsConfirmTransferModalOpen,
  setIsUpdateRestrictionDatesModalOpen,
  getPlacementIdByExternalIdAndRedirect,
  setRedirectUrl,
  getUpdateRestrictionAllowed,
  setUploadRfiModalOpen,
  initiatePlacementTransfer,
  getAccommodationStatusForAccommodation,
  checkIfSiteOnboardedToDecisionHub,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { clearProcessingMessagesData } from "src/store/newJmrPageSlice";
import UpdatePlacementModal from "./updatePlacementModal/updatePlacementModal";
import UpdatePlacementModalV2 from "./updatePlacementModal/updatePlacementModalV2";
import PlacementSelections from "./subpages/placementSelections/placementSelections";
import Restrictions from "./subpages/restrictions/restrictions";
import EmployeeInformation from "./subpages/employeeInformation/employeeInformation";
import RevisionHistory from "./subpages/revisionHistory/revisionHistory";
import Correspondence from "./subpages/correspondence/correspondence";
import Row from "@amzn/meridian/row";
import { useDispatch } from "react-redux";
import TwpaModal from "./twpaModal/twpaModal";
import OomdModal from "./oomdModal/oomdModal";
import SendEmailModal from "./sendEmailModal/sendEmailModal";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";
import { setJmrFlowType, JmrFlowTypes } from "src/store/newJmrPageSlice";
import { getAllowedSites } from "src/store/siteSlice";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import { PLACEMENT_DETAILS_BASEURL } from "src/constants/Routes";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { AUSTIN } from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/constant";
import { constructIncidentLink, constructMedicalAttachmentsLink } from "src/utils/constructRTWLinks";
import { stripOutLeadingHash } from "src/utils/helpers";
import Link from "@amzn/meridian/link";
import InitiateTransferModal from "./initiateTransferModal/initiateTransferModal";

const placementDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { userLoading, featureFlags } = useAppSelector((state) => state.user);
  const {
    selectedTab,
    placementDetails,
    isConfirmDeleteModalOpen,
    isConfirmUpdateRestrictionsModalOpen,
    isAlternativePlacementModalOpen,
    isUpdateRestrictionDatesModalOpen,
    isConfirmTransferModalOpen,
    redirectUrl,
    isUploadRfiModalOpen,
    isSiteParticipatingInDecisionHubPilot
  } = useAppSelector((state) => state.placementDetailsPage);
  const { accommodationType, processingMessages, externalCaseId } = useAppSelector((state) => state.newJmrPage);

  let { placementId, type, caseId, login } = useParams();
  const initialRender = useRef(true);

  const { allowedSites } = useAppSelector((state) => state.sites);

  const dispatchToast = (type: "error" | "success", message: string, incidentLink?: string | null) => {
    dispatch(
      createToast({
        type: type,
        message: (
          <>
            <div>{message}</div>
            {incidentLink && (
              <div>
                <Link target="_blank" rel="noopener noreferrer" type="secondary" href={incidentLink}>
                  {incidentLink}
                </Link>
              </div>
            )}
          </>
        ),
        timeout: TOAST_TIMEOUT,
      })
    );
  };

  useEffect(() => {
    if (processingMessages && accommodationType === "AUSTIN") {
      const incidentLink = externalCaseId && constructIncidentLink(stripOutLeadingHash(externalCaseId));
      processingMessages.forEach((message) => {
        if (message.type === "ERROR") {
          if(message.content.includes("AUSTIN")) {
            dispatchToast("error", message.content, incidentLink);
          } else {
            dispatchToast("error", message.content)
          }
        } else if (message.type === "SUCCESS") {
          dispatchToast("success", message.content);
        }
      });
      dispatch(clearProcessingMessagesData());
    }
  }, [processingMessages]);

  /**
   * Delete a placement
   */
  const removePlacement = () => {
    if (placementId) {
      dispatch(deletePlacement(+placementId));
      dispatch(setIsConfirmDeleteModalOpen(false));
      navigate("/placementsOverview");
    }
  };

  /**
   * Navigate to update JMR flow
   */
  const navigateToUpdateJmr = (updateType: JmrFlowTypes) => {
    navigate(`/newJmr/${placementId}/update/${updateType}`);
    dispatch(setIsRestrictionsModalOpen(false));
    dispatch(setIsAlternativePlacementModalOpen(false));
    dispatch(setJmrFlowType(updateType));
  };

  useEffect(() => {
    if (userLoading === "fulfilled") {
      initialRender.current = true;
      dispatch(getAllowedSites());
      if (type && caseId && login) {
        dispatch(getPlacementIdByExternalIdAndRedirect({ type: type, caseId: caseId, login: login }));
      } else if (placementId) {
        dispatch(getPlacementDetails(+placementId));
        dispatch(setPlacementId(+placementId));
      }
    }
    return () => {
      dispatch(setPlacementDetails({}));
    };
  }, [userLoading, placementId]);

  useEffect(() => {
    if (placementDetails.fc && allowedSites.length > 0 && initialRender.current) {
      dispatch(getEmailSettingsForSite());
      initialRender.current = false;
    }
  }, [placementDetails.fc, placementDetails?.siteId, allowedSites, placementDetails]);

  useEffect(() => {
    if(placementDetails.siteId) {
      dispatch(checkIfSiteOnboardedToDecisionHub(placementDetails.siteId));
    }

    if(isSiteParticipatingInDecisionHubPilot !== undefined && placementId !== undefined) {
      const shouldShowStaticStatuses = !featureFlags.DP_INTEGRATION_P0 || !isSiteParticipatingInDecisionHubPilot;
      shouldShowStaticStatuses ? dispatch(getAccommodationStatus(placementDetails.siteId)) : dispatch(getAccommodationStatusForAccommodation(+placementId));
    }

  }, [placementDetails.siteId, isSiteParticipatingInDecisionHubPilot]);

  const isCanadaPlacement = () => {
    return placementDetails?.countryId === COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID;
  };

  useEffect(() => {
    if (redirectUrl !== "") {
      dispatch(setRedirectUrl(""));
      if (redirectUrl.startsWith("/placementDetails")) {
        dispatch(
          createToast({
            type: "success",
            message: "Existing RTW placement found, redirecting to the Placement Details page...",
            timeout: TOAST_TIMEOUT,
          })
        );
        navigate(redirectUrl);
      } else if (redirectUrl.startsWith("/placementsOverview")) {
        navigate(`${redirectUrl}`);
      } else {
        dispatch(
          createToast({
            type: "success",
            message: "An RTW placement cannot be found for the selected AUSTIN Case. Please create one below...",
            timeout: TOAST_TIMEOUT,
          })
        );
        navigate(`${redirectUrl}/${AUSTIN}/${login}/${caseId}`);
      }
    }
  }, [redirectUrl]);

  return (
    <div className="placement-details">
      <Breadcrumbs
        breadcrumbList={[
          {
            label: `Placements Overview ${placementDetails.fc}`,
            url: "/placementsOverview",
          },
          {
            label: `Placement Details (${placementDetails.associateFullName || placementDetails.login})`,
            url: `${PLACEMENT_DETAILS_BASEURL}/${placementId}`,
          },
        ]}
      />
      {placementId && (
        <div className="placement-details__content">
          <TopOptions />
          <TopDetails />
          <TopTabs />
          {featureFlags.DP_INTEGRATION_P0 && isSiteParticipatingInDecisionHubPilot ? <UpdatePlacementModalV2/> : <UpdatePlacementModal />}
          {isCanadaPlacement() ? <OomdModal /> : <TwpaModal />}
          <SendEmailModal />
          <Row spacingInset={"400 none 600 none"} widths={["fill"]}>
            {selectedTab === "placement-selections" && <PlacementSelections />}
            {selectedTab === "restrictions" && <Restrictions />}
            {selectedTab === "employee-information" && <EmployeeInformation />}
            {selectedTab === "revision-history" && <RevisionHistory />}
            {selectedTab === "correspondence" && <Correspondence />}
          </Row>
        </div>
      )}
      <ConfirmModal
        modalTitle="Confirm delete?"
        modalDescription="This will permanently delete this placement"
        open={isConfirmDeleteModalOpen}
        setOpen={(open) => dispatch(setIsConfirmDeleteModalOpen(open))}
        onConfirm={() => removePlacement()}
      />
      <ConfirmModal
        modalTitle="Update Placement Restrictions?"
        modalDescription="If restrictions and start/end dates have changed, Restrictions will navigate you to
a page where you will enter new restrictions and dates."
        open={isConfirmUpdateRestrictionsModalOpen}
        setOpen={(open) => dispatch(setIsConfirmUpdateRestrictionsModalOpen(open))}
        onConfirm={async () => {
          if (placementId) {
            const isUpdateRestrictionAllowed: boolean = await dispatch(
              getUpdateRestrictionAllowed(parseInt(placementId))
            ).unwrap();

            if (isUpdateRestrictionAllowed) {
              navigateToUpdateJmr(JmrFlowTypes.NewRestrictions);
            } else {
              dispatch(setIsConfirmUpdateRestrictionsModalOpen(false));
              dispatch(setUploadRfiModalOpen(true));
            }
          } else {
            navigateToUpdateJmr(JmrFlowTypes.NewRestrictions);
          }
        }}
      />
      <ConfirmModal
        modalTitle="Upload RFI or WSR?"
        modalDescription="You must upload a new RFI or WSR to update the restrictions for this placement. If a new RFI or WSR has already been uploaded, please wait a few minutes and try again."
        open={isUploadRfiModalOpen}
        setOpen={(open) => dispatch(setUploadRfiModalOpen(open))}
        onConfirm={() => {
          dispatch(setUploadRfiModalOpen(false));
        }}
        includeLink={true}
        linkText="Austin Incident Link"
        linkUrl={placementDetails.externalCaseId && constructMedicalAttachmentsLink(placementDetails.externalCaseId)}
        showCancelButton={false}
      />
      <ConfirmModal
        modalTitle="Select Alternative Placements?"
        modalDescription="If the site decision maker accepted an alternative placement, Placement
Selections(s) will navigate you to a page where you will select new placements."
        open={isAlternativePlacementModalOpen}
        setOpen={(open) => dispatch(setIsAlternativePlacementModalOpen(open))}
        onConfirm={() => navigateToUpdateJmr(JmrFlowTypes.AlternativePlacementRecommended)}
      />
      <ConfirmModal
        modalTitle="Update Restriction Dates?"
        modalDescription="If ONLY start/end dates have changed (restrictions have remained the same),
Restriction Dates will navigate you to a page where you will select new start and end dates."
        open={isUpdateRestrictionDatesModalOpen}
        setOpen={(open) => dispatch(setIsUpdateRestrictionDatesModalOpen(open))}
        onConfirm={() => navigateToUpdateJmr(JmrFlowTypes.UpdateDates)}
      />
      <InitiateTransferModal />
      <ConfirmModal
        modalTitle="Confirm Transfer?"
        modalDescription="Once transferred, your site will no longer have access to this RTW placement."
        open={isConfirmTransferModalOpen}
        setOpen={(open) => dispatch(setIsConfirmTransferModalOpen(open))}
        onConfirm={() => {
          dispatch(initiatePlacementTransfer());
          dispatch(setIsConfirmTransferModalOpen(false))
        }}
      />
    </div>
  );
};

export default placementDetails;
