import React, { useState } from "react";
import Message, { MessageFooter } from "@amzn/meridian/message";
import { BADGE_PHOTO_THUMBNAIL_URL } from "src/config/ExternalLinks";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Textarea from "@amzn/meridian/textarea";
import Button from "@amzn/meridian/button";
import Thumbnail from "@amzn/meridian/thumbnail";
import { MOCK_CORRESPONDENCE_MESSAGES } from "./mockCorrespondenceMessages";
import { useAppSelector } from "src/store/store";
const correspondence = () => {
  const [message, setMessage] = useState("");
  const { alias } = useAppSelector((state) => state.user);
  return (
    <>
      <Column>
        <Row widths={["grid-8"]}>
          <Box type='outline' spacingInset='500' minHeight={400} maxHeight={400} overflowY='scroll'>
            <Column>
              {MOCK_CORRESPONDENCE_MESSAGES.map((correspondence) => {
                return (
                  <Message
                    name={correspondence.sender}
                    avatarImageSource={`${BADGE_PHOTO_THUMBNAIL_URL}${correspondence.alias}`}
                    datetime={correspondence.dateTime}
                    type={correspondence.fromDecisionHub ? "incoming" : "outgoing"}
                    maxWidth='350px'
                    size='large'
                  >
                    {correspondence.message}
                    <MessageFooter>{correspondence.dateTime.split(" ")[0]}</MessageFooter>
                  </Message>
                );
              })}
            </Column>
          </Box>
        </Row>
        <Row widths={["fit", "grid-5", "grid-2"]}>
          <Thumbnail source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`} />
          <Textarea
            value={message}
            onChange={setMessage}
            placeholder='Write your correspondence message here...'
            error={false}
          />
          <Button size='large'>Post Correspondence</Button>
        </Row>
      </Column>
    </>
  );
};

export default correspondence;
