import Tab, { TabGroup } from "@amzn/meridian/tab";
import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { useAppSelector } from "src/store/store";

const topTabs = () => {
  const { selectedTab, isSiteParticipatingInDecisionHubPilot, placementDetails } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const { featureFlags } = useAppSelector((state) => state.user);
  const dispatch = useDispatch();
  return (
    <TabGroup value={selectedTab} onChange={(selectedTab) => dispatch(setSelectedTab(selectedTab))}>
      <Tab value='placement-selections' data-cy='placement-details__placement-tab'>
        Placement Selection(s)
      </Tab>
      <Tab value='restrictions' data-cy='placement-details__restriction-tab'>
        Restrictions
      </Tab>
      <Tab value='employee-information' data-cy='placement-details__home-process-tab'>
        Employee Information
      </Tab>
      <Tab value='revision-history' data-cy='placement-details__revision-tab'>
        Revision History
      </Tab>
      {featureFlags["DP_INTEGRATION_CORRESPONDENCE"] &&
        isSiteParticipatingInDecisionHubPilot &&
        placementDetails.accommodationType === "AUSTIN" && (
          <Tab value='correspondence' data-cy='placement-details__correspondence-tab'>
            Correspondence
          </Tab>
        )}
    </TabGroup>
  );
};

export default topTabs;
