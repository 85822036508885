export const MOCK_CORRESPONDENCE_MESSAGES = [
    {
        id: 1,
        sender: 'Wei Wang',
        alias: 'wwangam',
        dateTime: '2023-05-15 16:00',//'2021-03-28 10:30',
        message: 'Can you change the job to OB Pick instead and resend the placement for decision?',
        fromDecisionHub: true
    },
    {
        id: 2,
        sender: 'Gary Zhang',
        alias: 'ghzhang',
        dateTime: '2023-05-15 16:08',
        message: 'No. That is not safe. AA \'s right wrist is hurt too bad.',
        fromDecisionHub: false
    },
    {
        id: 3,
        sender: 'Wei Wang',
        alias: 'wwangam',
        dateTime: '2023-05-15 16:30',
        message: 'Would an ice bath and a reduced work schedule help? Say 3 hours instead of 8 and for the remaining 5 put them in TLD Audit Support.',
        fromDecisionHub: true
    },
    {
        id: 4,
        sender: 'Gary Zhang',
        alias: 'ghzhang',
        dateTime: '2023-05-16 09:00',
        message: 'AA isn\'t trained for Audits. What about TLD Tote Management?',
        fromDecisionHub: false
    },
    {
        id: 5,
        sender: 'Wei Wang',
        alias: 'wwangam',
        dateTime: '2023-05-16 10:12',
        message: 'That works! Resend the placement and I\'ll approve it',
        fromDecisionHub: true
    }
];